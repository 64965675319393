@import "react-toastify/dist/ReactToastify.css";
@import 'sweetalert2/src/sweetalert2.scss';
@import 'react-quill/dist/quill.snow.css';

$primary-color:#006747;
$secondary-color: #212529;
$black-color: #000000;
$black-color-light: #6C757D;
$yellow-color:#FFC850;
$red-color:#FF0000;

.cursor-pointer{
    cursor: pointer;
}